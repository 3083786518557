import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import CONFIG from "config/config";
import JsBarcode from "jsbarcode";
import { checkURLImage } from "react-lib/apps/common/checkURLImage";

const FORM_NAME = "FormAppointmentQueue";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormAppointmentQueue = async (props: any) => {

  let isQRCodeURLValid = false 
  if (props.data?.item?.qrcode_div) {
    isQRCodeURLValid = await checkURLImage(props.data?.item?.qrcode_div)
  }
  
  console.log("FormAppointmentQueue props: ", props);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 16.83,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  let dateStringThai = "";
  if (props.data.item.created) {
    dateStringThai = new Date(props.data.item.created).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  }

  return {
    // A4: [595.28, 841.89],
    // A6: [297.64, 419.53],
    pageSize: "A6",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    pageMargins: [20, 60, 20, 100],
    styles: {
      facultyHeader: {
        fontSize: 19,
        bold: true,
      },
      facultyName: {
        fontSize: 9,
        bold: true,
      },
      facultyDetail: {
        fontSize: 11,
        bold: true,
      },
      facultyValue: {
        fontSize: 11,
      },
      divisionHeaderBold: {
        fontSize: 16,
        bold: true,
      },
      headerTitle: {
        fontSize: 16,
      },
      divisionSubHeader: {
        fontSize: 14,
        bold: true,
      },
      divisionSubValue: {
        fontSize: 14,
      },
      tableHeader: {
        fontSize: 10,
        alignment: "center",
        bold: true,
      },
      tableTitle: {
        fontSize: 14,
        alignment: "center",
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 11,
      },
      fieldValue: {
        fontSize: 11,
        alignment: "left",
      },
      footerValue: {
        fontSize: 10,
        alignment: "left",
      },
    },

    header: (currentPage: any, pageCount: any, pageSize: any) => {
      return {
        margin: [20, 10, 20, 10],
        stack: [
          {
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    stack: [{ margin: [0, 0, 0, 0], width: 50, image: "logo", alignment: "left" }],
                  }
                : null,
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    width: "80%",
                    text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                    style: "tableHeader",
                  }
                : null,
            ],
            // ...companyLogoForm,
          },
          { text: "ใบคิว", style: "tableTitle" },
        ],
      };
    },
    content: [
      {
        columns: [
          { text: "คลินิก", style: "fieldKey", width: "20%" },
          { text: props.data.division_name, style: "fieldValue", width: "70%" },
        ],
      },
      {
        columns: [
          { text: "ชื่อ-นามสกุล", style: "fieldKey", width: "20%" },
          { text: props.data.item.patient_name, style: "fieldValue", width: "70%" },
        ],
      },

      {
        columns: [
          { text: "HN", style: "fieldKey", width: "20%" },
          { text: props.data.item.patient_hn, style: "fieldValue", width: "70%" },
        ],
      },

      {
        table: {
          headerRows: 1,
          widths: [250], // A6
          body: [[""], [""]],
        },
        layout: "headerLineOnly",
      },

      {
        columns: [
          { text: "กลุ่มงานคิว", style: "fieldKey", width: "20%" },
          { text: props.data.waiting_list_name, style: "fieldValue", width: "70%" },
        ],
      },

      {
        columns: [
          { text: "วันที่เข้าคิว", style: "fieldKey", width: "20%" },
          { text: dateStringThai, style: "fieldValue", width: "70%" },
        ],
      },

      {
        columns: [
          { text: "เลขที่คิว", style: "fieldKey", width: "20%" },
          { text: props.data.item.code, style: "fieldValue", width: "70%" },
        ],
      },

      {
        columns: [
          { text: "รายละเอียด", style: "fieldKey", width: "20%" },
          { text: props.data.item.note, style: "fieldValue", width: "70%" },
        ],
      },

      // {
      //   table: {
      //     headerRows: 1,
      //     widths: ["5%", "8%", "10%", "5%", "10%", "12.5%", "15%", "15%", "10%", "10%"],
      //     body: [
      //       [
      //         { text: "ลำดับ", style: "tableHeader", alignment: "center" },
      //         { text: "เลขที่คิว", style: "tableHeader", alignment: "center" },
      //         { text: "วันที่เข้าคิว", style: "tableHeader", alignment: "center" },
      //         { text: "HN", style: "tableHeader" },
      //         { text: "ชื่อ-สกุล", style: "tableHeader" },
      //         { text: "เบอร์โทรติดต่อ", style: "tableHeader" },
      //         { text: "แพทย์/นิสิต", style: "tableHeader" },
      //         { text: "ประเภทการรักษา", style: "tableHeader" },
      //         { text: "สถานะ", style: "tableHeader" },
      //         { text: "รายละเอียด", style: "tableHeader" },
      //       ],
      //     ].concat(
      //       (props.data?.orders || [])?.map((row: any, index: number) => [
      //         { text: (index + 1).toString(), style: "fieldValue", alignment: "center" },
      //         { text: row.patient_hn, style: "fieldValue" },
      //         { text: row.queueNo ? row.queueNo : "", style: "fieldValue" },
      //         { text: row.queueDate ? row.queueDate : "", style: "fieldValue" },
      //         {
      //           text: row.patient_name ? row.patient_name.replace(/ \(.*\)$/g, "") : "",
      //           style: "fieldValue",
      //         },
      //         {
      //           text: row.patient_tel ? row.patient_tel.replace(/(^\d{3})/g, "$1-") : "",
      //           style: "fieldValue",
      //         },
      //         {
      //           text: row.provider_name ? `${row.provider_name} (${row.provider_code})` : "ไม่ระบุ",
      //           style: "fieldValue",
      //         },
      //         { text: row.type_name || "", style: "fieldValue" },
      //         { text: row.status_name || "", style: "fieldValue" },
      //         { text: row.note, style: "fieldValue" },
      //       ])
      //     ),
      //   },
      // },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      margin: [20, 0, 20, 0],
      stack: [
        {
          table: {
            headerRows: 1,
            widths: [250], // A6
            body: [[""], [""]],
          },
          layout: "headerLineOnly",
        },
        {
          text: `เมื่อถึงคิวทาง รพ. จะติดต่อกลับ/สอบถามที่ ${
            props.data?.item?.division_detail || "-"
          }`,
          style: "footerValue",
        },
        {
          columns: [
            {
              width: "30%",
              margin: [0, 2.5, 0, 0],
              stack: [
                {
                  image: textToBase64BarcodeBlob(props.data?.item?.patient_hn),
                  width: 80,
                  height: 35,
                  alignment: "center",
                },
                { text: "HN", style: "fieldKey", alignment: "center" },
              ],
            },
            {
              width: "45%",
              margin: [5, 2.5, 0, 0],
              alignment: "left",
              stack: [
                {
                  text: `วันเวลาที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]`,
                  style: "footerValue",
                },
                { text: `ผู้นำคิวเข้า : ${props.data.item.create_by}.`, style: "footerValue" },
              ],
            },

            props.data?.item?.qrcode_div
              ? {
                  width: "25%",
                  margin: [0, 2.5, 0, 0],
                  alignment: "left",
                  stack: [
                    {
                      text: "QR code ติดต่อ",
                      style: "footerValue",
                      alignment: "center",
                    },
                    {
                      image: "qrcodeDivision",
                      height: 39,
                      width: 39,
                      alignment: "center",
                    },
                  ],
                }
              : null,
          ],
        },
      ],
    }),
    images: {
      ...images,
      qrcodeDivision: isQRCodeURLValid ? props.data?.item?.qrcode_div : ""
    },
  };
};

export default FormAppointmentQueue;
